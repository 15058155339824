import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from '@reach/router';
import Sidebar from 'components/sidebar';
import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Links from 'components/links';
import Image from 'components/image';
import SEO from 'components/seo';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
import StartHereLinks from 'components/start-here-links';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`All the AAP Emails`}</h1>
    <p><strong parentName="p">{`Start exploring emails in the side menu or choose your own adventure:`}</strong></p>
    <StartHereLinks mdxType="StartHereLinks" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      